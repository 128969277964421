
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFilter, faPen, faTrash, faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, Card, CardContent, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";

import Tables from '../../../Components/Table';
import Alertavel from "../../../Components/Alertavel";
import ConfirmDialog from "../../../Components/Dialog/Confirm";
import ResponsiveDialog from "../../../Components/Dialog/Dialog";
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import { dataLimitation, dataParentCategory, dataTypeCategory } from "../constants";

import { User } from "../../../Models/User";
import { CategoryFinances } from "../../../Models/Finances/Category";
import { capitalizeFirstLetter } from "../../../Services/globalFunction";

import "../../../App.css";

const headers = ['Categoria Pai ', 'Nome', 'Tipo Categoria', 'Limitação', 'Ativa'];

const ListCategory = ({ module }) => {

  const [categories, setCategories] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);

  const [deleteItemId, setDeleteItemId] = useState(null);

  const [title, setTitle] = useState("LISTAGEM DE CATEGORIAS");
  const [fullscreen, setFullscreen] = useState(false);

  const [contentModal, setContentModal] = useState('');
  const [contentAlert, setContentAlert] = useState("");
  const [contentDialog, setContentDialog] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const user = User.searchUser();
  const token = user.token;

  const [formData, setFormData] = useState({
    tipo_categoria: 0,
    tipo_limitacao: 0,
    ativa: 0,
  });

  const Load_Categories = useCallback(async () => {
    const parameters = {
      tipo_limitacao: formData.tipo_limitacao,
      tipo_categoria: formData.tipo_categoria,
      ativa: formData.ativa,
    };

    const categories = new CategoryFinances(token);
    const Category = await categories.SearchCategories(parameters).then(response => {
      if (!response.erro) {
        const allCategories = categories => categories.flatMap(({ subcategorias, ...categoryData }) => [categoryData, ...(subcategorias ? allCategories(subcategorias) : [])]);
        return allCategories(response);
      } else {
        setContentAlert("Você não possui permissão para listar as categorias. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataCategories(Category);

  }, [formData.ativa, formData.tipo_categoria, formData.tipo_limitacao, token]);

  const Load_Category = useCallback(async (category_id) => {

    const category = new CategoryFinances(token);
    const searchCategory = await category.SearchCategory(category_id).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para consultar a categoria. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    return searchCategory;
  }, [token]);

  const modalClose = () => {
    setOpenModal(false);
    setFullscreen(false);
  };

  const handleView = (id) => {

    const dataView = dataCategories.find((val) => val.id === id);
    const { filiais_limitadas, fazendas_limitadas, nome, descricao, categoria_pai_nome, tipo_categoria, tipo_limitacao } = dataView
    const filiais = []
    const fazendas = []

    filiais_limitadas.forEach((val, i) => {
      filiais.push({ id: i, nome: val.nome });
    });

    fazendas_limitadas.forEach((val, i) => {
      fazendas.push({ id: i, nome: val.nome });
    })

    const content = () => {
      return (
        <Grid container justifyContent="center">

          <Helmet>
            <title>Listagem de Categorias</title>
          </Helmet>

          <Grid item marginTop={4} marginBottom={4} lg={12} md={12} sm={12} xs={12} className="titles">
            <Typography variant="h5">Categorias</Typography>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

            <Card className="card-form">
              <CardContent>

                <Grid container>
                  <Typography variant="h6" className="title-accordion">Nome</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField type="text" className="disabled-textField" disabled value={nome} label="Nome da Categoria" />
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField multiline rows={4} type="text" className="disabled-textField" disabled value={descricao} label="Descrição da Categoria" />
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>


          {dataView.categoria_pai !== null && (
            <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
              <Card className="card-form">
                <CardContent>

                  <Grid container>
                    <Typography variant="h6" className="title-accordion">Categoria Pai</Typography>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item lg={12} md={6} sm={12} xs={12}>
                      <TextField className="disabled-textField" disabled label="Categoria" value={categoria_pai_nome} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Card className="card-form">
              <CardContent>

                <Grid container>
                  <Typography variant="h6" className="title-accordion">Tipo de Categoria</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled label="Tipo Categoria" value={capitalizeFirstLetter(tipo_categoria)} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item mb={8} lg={8} md={12} sm={12} xs={12}>
            <Card className="card-form">
              <CardContent>

                <Grid container>
                  <Typography variant="h6" className="title-accordion">Limitação</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled label="Tipo Limitação" value={capitalizeFirstLetter(tipo_limitacao)} />
                  </Grid>

                  {dataView.tipo_limitacao === 'FAZENDA' && (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <AutocompleteSelect isMultiple={true} id="fazendasView" label="Fazendas Limitadas" data={fazendas} value={fazendas} disabled={true} />
                    </Grid>
                  )}

                  {dataView.tipo_limitacao === 'FILIAL' && (
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <AutocompleteSelect isMultiple={true} id="filiaisView" label="Filiais Limitadas" data={filiais} value={filiais} disabled={true} />
                    </Grid>
                  )}

                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </Grid>
      );
    }

    setTitle("LISTAGEM DE CATEGORIA - #" + id);
    setContentModal(content);
    setFullscreen(true);
    setOpenModal(true);
  };

  const handleEdit = (id) => {
    let dataEdit = dataCategories.find((val) => val.id === id);

    if (dataEdit.ativa === "N") {
      setContentAlert("Não é possível editar uma categoria que possui status inativo !!");
      setOpenAlert(true);
    } else {
      const routes = module.rotas.find(element => element.tipo_rota === 'EDICAO' && element.referencia === 'categoria');
      navigate(`/financeiro${routes.rota}`, { state: { tipo: 'edicao', data: dataEdit } });
    }
  };

  // Click botão deletar
  const handleDelete = (id) => {
    let dataEdit = dataCategories.find((val) => val.id === id);
    if (dataEdit.ativa === "N" || dataEdit.qtd_subcategorias > 0 || dataEdit.qtd_registros > 0) {
      setContentAlert("Não é possível deletar uma categoria que possua os Status inativo, Subcategorias ou Registros Financeis !!");
      setOpenAlert(true);
    } else {
      setDeleteItemId(id);
      setTitle("Deletar Registro");
      setContentDialog("Você tem certeza que deseja excluir esta Categoria?");
      setOpenDialog(true);
    }

  };

  // Click confirmar remoção
  const handleDeleteConfirmed = async () => {
    const user = User.searchUser();
    const token = user.token;

    const remove = new CategoryFinances(token);
    remove.DeleteCategory(deleteItemId);

    setCategories([]);
    setDataCategories([]);
    setOpenDialog(false);
  };

  // Click cancelar remoção
  const handleDeleteCanceled = () => {
    setOpenDialog(false);
  };

  const handleStatus = useCallback(async (id, ativo) => {
    const { nome, categoria_pai, filiais_limitadas, fazendas_limitadas, tipo_categoria, tipo_limitacao } = await Load_Category(id);

    let parameters = {
      nome,
      categoria_pai,
      tipo_categoria,
      tipo_limitacao,
      filiais_limitadas: filiais_limitadas.map((val) => val.id),
      fazendas_limitadas: fazendas_limitadas.map((val) => val.id),
    }

    if (ativo === 'S') {
      parameters.ativa = 'N';
    } else {
      parameters.ativa = 'S';
    }

    const categories = new CategoryFinances(token);
    categories.UpdateCategory(id, parameters);

    setDataCategories([]);

  }, [Load_Category, token])

  const btn_status = (id, ativo, handleStatus) => {
    const title = ativo === 'S' ? 'Ativa' : 'Inativa';
    const icon = ativo === 'S' ? faCircleCheck : faCircleXmark;
    const classe = ativo === 'S' ? 'faCircleCheck' : 'faCircleXmark';

    return (
      <Tooltip title={title}>
        <IconButton className="btn-icon" onClick={() => handleStatus(id, ativo)}>
          <FontAwesomeIcon className={classe} icon={icon} />
        </IconButton>
      </Tooltip>
    );
  };

  // Botões da tabela
  const actions = (id) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Tooltip title="Visualizar">
          <Button className="ListBtn" onClick={() => handleView(id)}><FontAwesomeIcon className="font-icons-list" icon={faEye} /></Button>
        </Tooltip>
        <Tooltip title="Editar">
          <Button className="ListBtn" onClick={() => handleEdit(id)}><FontAwesomeIcon className="font-icons-list" icon={faPen} /></Button>
        </Tooltip>
        <Tooltip title="Deletar">
          <Button className="ListBtn" onClick={() => handleDelete(id)}><FontAwesomeIcon className="font-icons-list" icon={faTrash} /></Button>
        </Tooltip>
      </ButtonGroup>
    );
  };

  // Botão de filtros
  const filterClick = async (e) => {
    setOpenAlert(false);
    await Load_Categories();
  };

  useEffect(() => {

    const validateCategories = () => {

      const Categories = dataCategories.map((val) => {

        const CategoriesDataTable = {
          id: val.id,
          categoria_pai: val.categoria_pai_nome || 'Sem Categoria Pai',
          nome: val.nome,
          tipo_categoria: capitalizeFirstLetter(val.tipo_categoria),
          tipo_limitacao: capitalizeFirstLetter(val.tipo_limitacao),
          ativa: btn_status(val.id, val.ativa, handleStatus),
        };

        return CategoriesDataTable;
      });

      setCategories(Categories);
    };

    validateCategories();

  }, [dataCategories, handleStatus]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Financeiro</title>
      </Helmet>

      <Grid item marginTop={4} marginBottom={4} lg={12} md={12} sm={12} xs={12} className="titles">
        <Typography variant="h4">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Card className="card-form">
          <CardContent>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Selecione os campos, se quiser filtrar os dados</Typography>
            </Grid>

            <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>

              <Grid item lg={4} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="tipo_categoria"
                  label="Tipo Categoria"
                  isMultiple={false}
                  required={false}
                  disabled={false}
                  data={dataTypeCategory}
                  value={formData.categoria_pai}
                  onChange={(event) => setFormData({ ...formData, tipo_categoria: event ? event.id : 0 })}
                />
              </Grid>

              <Grid item lg={4} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="tipo_limitacao"
                  label="Tipo Limitação"
                  isMultiple={false}
                  required={false}
                  disabled={false}
                  data={dataLimitation}
                  value={formData.tipo_limitacao}
                  onChange={(event) => setFormData({ ...formData, tipo_limitacao: event ? event.id : 0 })}
                />
              </Grid>

              <Grid item lg={2} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="ativa"
                  label="Ativa ?"
                  isMultiple={false}
                  required={false}
                  disabled={false}
                  data={dataParentCategory}
                  value={formData.ativa}
                  onChange={(event) => setFormData({ ...formData, ativa: event ? event.id : 0 })}
                />
              </Grid>

              <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                <Tooltip title="Filtrar">
                  <Button className="btnfilter successBtn" fullWidth variant="contained" onClick={filterClick}><FontAwesomeIcon icon={faFilter} /> Filtrar</Button>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" >
              <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

              <ConfirmDialog
                title={title}
                text={"Deletar"}
                open={openDialog}
                content={contentDialog}
                onClose={handleDeleteCanceled}
                onConfirm={handleDeleteConfirmed}
              />
            </Grid>

          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} id="dataTable">
        <Card className="card-form">
          <CardContent>
            <Tables headers={headers} rows={categories} actions={actions} />
          </CardContent>
        </Card>
      </Grid>

      <ResponsiveDialog
        title={title}
        content={contentModal}
        onClose={modalClose}
        openModal={openModal}
        isFullscreen={fullscreen}
      />

    </Grid>
  )
}

export default ListCategory;