import React from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import AlertTitle from "@mui/material/AlertTitle";

export default function Alertavel({ title, type, open, content }) {
  return (
    <Grid item sx={{ width: '100%' }} marginTop={2}>
      <Collapse in={open}>
        <Alert sx={{ mb: 2 }} severity={type}>
          <AlertTitle>{title}</AlertTitle>
          {content}
        </Alert>
      </Collapse>
    </Grid>
  );
}