// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-content,
.dialog-title,
.dialog-footer {
  background-color: #fafbfc;
}`, "",{"version":3,"sources":["webpack://./src/Components/Dialog/css/index.css"],"names":[],"mappings":"AAAA;;;EAGE,yBAAyB;AAC3B","sourcesContent":[".dialog-content,\n.dialog-title,\n.dialog-footer {\n  background-color: #fafbfc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
