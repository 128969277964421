import { useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";

import "./css/index.css";
import "../../App.css";
import { useState } from "react";
import ResponsiveDialog from "../Dialog/Dialog";

export default function ProductCards({ product, isLoggedIn, modules }) {
  const [conteudo, setConteudo] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const modalClose = () => {
    setOpenModal(false);
    navigate("/");
  };

  const renderCardActionArea = ({ free, link, icon, title, permission }) => {
    const checkPermission = (permission) => {
      return modules.some((element) => element.nome_permissao === permission);
    };

    const handleClickAction = () => {
      if (free) {
        navigate(link);
      } else {
        if (!isLoggedIn) {
          navigate("/login");
        } else if (!checkPermission(permission)) {
          setConteudo("Você não possui permissão para acessar o módulo. Por favor, entre em contato com um administrador ou a pessoa responsável pela gestão da sua empresa para obter assistência.");
          setOpenModal(true);
          return;
        } else {
          navigate(link);
        }
      }
    };

    return (
      <CardActionArea className="btnCard" component="a" onClick={handleClickAction}>
        <Card className="cards">
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                {icon}
              </Grid>
              <Grid item xs={12} marginTop={4}>
                <Typography variant="h6" component="div">
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardActionArea>
    );
  };

  return product.map((val) => (
    <Grid item className="card-products" lg={5.7} md={5.7} sm={5.7} xs={5.7} margin={0.5} key={val.id}>
      <ResponsiveDialog
        content={conteudo}
        openModal={openModal}
        title="Atenção"
        onClose={modalClose}
        isFullscreen={false}
      />

      {renderCardActionArea(val)}
    </Grid>
  ));
}
