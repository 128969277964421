// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-carousel {
  color: #707070 !important;
  background-color: #fafbfc !important;
  box-shadow: 1px 2px 2px -1px #e7e7e7 !important;
  width: 98% !important;
}

.card-carousel-content {
  padding: 10px !important;
}

.temperaturaGrid {
  border-left: 4px solid #de675a;
}

.precipitacaoGrid {
  border-left: 4px solid #90cbf8;
}

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/Components/Carousel/Cards/css/index.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,oCAAoC;EACpC,+CAA+C;EAC/C,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".card-carousel {\n  color: #707070 !important;\n  background-color: #fafbfc !important;\n  box-shadow: 1px 2px 2px -1px #e7e7e7 !important;\n  width: 98% !important;\n}\n\n.card-carousel-content {\n  padding: 10px !important;\n}\n\n.temperaturaGrid {\n  border-left: 4px solid #de675a;\n}\n\n.precipitacaoGrid {\n  border-left: 4px solid #90cbf8;\n}\n\n.carousel-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
