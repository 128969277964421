
import { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faEye, faFilter, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, ButtonGroup, Card, CardContent, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";

import Tables from '../../../Components/Table';
import Alertavel from "../../../Components/Alertavel";
import ConfirmDialog from "../../../Components/Dialog/Confirm";
import ResponsiveDialog from "../../../Components/Dialog/Dialog";
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import { dataParentCategory } from "../constants";

import { capitalizeFirstLetter, getCurrentDate } from "../../../Services/globalFunction";

import { User } from "../../../Models/User";
import { Finances } from "../../../Models/Finances/Finances";

import "../../../App.css";

const headers = ['Data', 'Valor', 'Recorrente', 'Quitada', 'Ativa'];

const ListFinances = ({ module }) => {

  const [finances, setFinances] = useState([]);
  const [dataFinances, setDataFinances] = useState([]);

  const [deleteItemId, setDeleteItemId] = useState(null);

  const [dataIni, setDataIni] = useState(getCurrentDate(0, 30, 'subtrai'));
  const [dataEnd, setDataEnd] = useState(getCurrentDate(0, 30, 'soma'));
  const [dataActive, setDataActive] = useState(0);

  const [title, setTitle] = useState("LISTAGEM DE FINANÇAS");
  const [fullscreen, setFullscreen] = useState(false);

  const [contentModal, setContentModal] = useState('');
  const [contentAlert, setContentAlert] = useState("");
  const [contentDialog, setContentDialog] = useState('');

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const user = User.searchUser();
  const token = user.token;

  const Load_Finances = useCallback(async () => {
    const parameters = { data_inicial: dataIni, data_final: dataEnd, ativo: dataActive }

    const finances = new Finances(token);
    const searchFinances = await finances.SearchFinances(parameters).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você Não possui permissão para listar as finanças. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataFinances(searchFinances);

  }, [dataIni, dataEnd, dataActive, token]);

  const Load_Finance = useCallback(async (id) => {

    const finances = new Finances(token);
    const searchFinance = await finances.SearchFinance(id).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você Não possui permissão para lista a finança. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    return searchFinance;

  }, [token]);

  const modalClose = () => {
    setOpenModal(false);
    setFullscreen(false);
  };

  const handleView = (id) => {

    const dataView = dataFinances.find((val) => val.id === id);

    const content = () => {
      return (
        <Grid container justifyContent="center">

          <Helmet>
            <title>Listagem de Finança</title>
          </Helmet>

          <Grid item marginTop={4} marginBottom={4} lg={12} md={12} sm={12} xs={12} className="titles">
            <Typography variant="h5">Finanças</Typography>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
            <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

            <Card className="card-form">
              <CardContent>

                <Grid container>
                  <Typography variant="h6" className="title-accordion">Data</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField type="date" className="disabled-textField" disabled value={dataView.data} label="Data" />
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField multiline rows={4} type="text" className="disabled-textField" disabled value={dataView.descricao} label="Descrição" />
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>

            <Card className="card-form">
              <CardContent>

                <Grid container>
                  <Typography variant="h6" className="title-accordion">Categoria</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled label="Categoria" value={capitalizeFirstLetter(dataView.categoria_nome)} />
                  </Grid>
                </Grid>

                {dataView.fazenda !== null && (
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField className="disabled-textField" disabled label="Categoria" value={capitalizeFirstLetter(dataView.fazenda_nome)} />
                    </Grid>
                  </Grid>
                )}

                {dataView.filial !== null && (
                  <Grid container spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField className="disabled-textField" disabled label="Categoria" value={capitalizeFirstLetter(dataView.filial_nome)} />
                    </Grid>
                  </Grid>
                )}

              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>

            <Card className="card-form">
              <CardContent>

                <Grid container>
                  <Typography variant="h6" className="title-accordion">Recorrência e Quitação</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled label="Recorrente?" value={dataView.recorrente === 'S' ? 'Sim' : 'Não'} />
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled label="Quitado?" value={dataView.quitado === 'S' ? 'Sim' : 'Não'} />
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>

          <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>

            <Card className="card-form">
              <CardContent>

                <Grid container>
                  <Typography variant="h6" className="title-accordion">Valor</Typography>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField className="disabled-textField" disabled label="Valor" value={parseFloat(dataView.valor)} />
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>

        </Grid>
      );
    }

    setTitle("LISTAGEM DE FINANÇA - #" + id);
    setContentModal(content);
    setFullscreen(true);
    setOpenModal(true);
  };

  const handleEdit = (id) => {
    let dataEdit = dataFinances.find((val) => val.id === id);

    if (dataEdit.ativa === "N") {
      setContentAlert("Não é possível editar uma finança que possui status inativo !!");
      setOpenAlert(true);
    } else {
      const routes = module.rotas.find(element => element.tipo_rota === 'EDICAO' && element.referencia === 'financas');
      if(routes){
        navigate(`/financeiro${routes.rota}`, { state: { tipo: 'edicao', data: dataEdit } });
      }else{
        setContentAlert("Não é possível editar o registro, tente novamente mais tarde !!");
        setOpenAlert(true);
      }
    }
  };

  const handleStatus = useCallback(async (id, campo, status_campo) => {

    const { data, valor, quitado, descricao, categoria, recorrente, filial, fazenda, ativo } = await Load_Finance(id);

    let parameters = {
      data,
      valor,
      filial,
      fazenda,
      descricao,
      categoria,
    }

    if (campo === 'recorrencia') {
      parameters.ativo = ativo;
      parameters.quitado = quitado;
      parameters.recorrente = status_campo === 'S' ? 'N' : 'S';
    } else if (campo === 'status') {
      parameters.quitado = quitado;
      parameters.recorrente = recorrente;
      parameters.ativo = status_campo === 'S' ? 'N' : 'S';
    } else {
      parameters.ativo = ativo;
      parameters.recorrente = recorrente;
      parameters.quitado = status_campo === 'S' ? 'N' : 'S';
    }

    const finances = new Finances(token);
    finances.UpdateFinances(id, parameters);

    setDataFinances([]);

  }, [Load_Finance, token])

  const btn_status = (id, campo, valor, handleStatus) => {
    let title = '';

    if (campo === 'recorrencia') {
      title = valor === 'S' ? 'Recorrente' : 'Não-Recorrente';
    } else if (campo === 'status') {
      title = valor === 'S' ? 'Ativa' : 'Inativa';
    } else {
      title = valor === 'S' ? 'Quitada' : 'Não-Quitada';
    }

    const icon = valor === 'S' ? faCircleCheck : faCircleXmark;
    const classe = valor === 'S' ? 'faCircleCheck' : 'faCircleXmark';

    return (
      <Tooltip title={title}>
        <IconButton className="btn-icon" onClick={() => handleStatus(id, campo, valor)}>
          <FontAwesomeIcon className={classe} icon={icon} />
        </IconButton>
      </Tooltip>
    );
  };

  // Click botão deletar
  const handleDelete = (id) => {
    let dataEdit = dataFinances.find((val) => val.id === id);

    if (dataEdit.ativo === "N") {
      setContentAlert("Não é possível deletar uma finança que possua o Status inativo !!");
      setOpenAlert(true);
    } else {
      setDeleteItemId(id);
      setTitle("Deletar Registro");
      setContentDialog("Você tem certeza que deseja excluir esta Finança?");
      setOpenDialog(true);
    }
  };

  // Click confirmar remoção
  const handleDeleteConfirmed = async () => {
    const user = User.searchUser();
    const token = user.token;

    const remove = new Finances(token);
    remove.DeleteFinances(deleteItemId);

    setFinances([]);
    setDataFinances([]);
    setOpenDialog(false);
  };

  // Click cancelar remoção
  const handleDeleteCanceled = () => {
    setOpenDialog(false);
  };

  //Change Data Inicial
  const handleInitialDate = (event) => {
    const selectedValue = event.target.value
    setDataIni(selectedValue);
  };

  //Change Data Final
  const handleEndDate = (event) => {
    const selectedValue = event.target.value
    setDataEnd(selectedValue);
  };

  // Botões da tabela
  const actions = (id) => {
    return (
      <ButtonGroup size="small" variant="text" aria-label="text button group">
        <Tooltip title="Visualizar">
          <Button className="ListBtn" onClick={() => handleView(id)}><FontAwesomeIcon className="font-icons-list" icon={faEye} /></Button>
        </Tooltip>
        <Tooltip title="Editar">
          <Button className="ListBtn" onClick={() => handleEdit(id)}><FontAwesomeIcon className="font-icons-list" icon={faPen} /></Button>
        </Tooltip>
        <Tooltip title="Deletar">
          <Button className="ListBtn" onClick={() => handleDelete(id)}><FontAwesomeIcon className="font-icons-list" icon={faTrash} /></Button>
        </Tooltip>
      </ButtonGroup>
    );
  };

  // Botão de filtros
  const filterClick = async (e) => {
    setOpenAlert(false);
    await Load_Finances();
  };

  useEffect(() => {

    const validateFinances = () => {

      const Finances = dataFinances.map((val) => {

        const FinancesDatatable = {
          id: val.id,
          data: val.data,
          valor: parseFloat(val.valor),
          recorrente: btn_status(val.id, 'recorrencia', val.recorrente, handleStatus),
          quitado: btn_status(val.id, 'quitacao', val.quitado, handleStatus),
          ativo: btn_status(val.id, 'status', val.ativo, handleStatus),
        };

        return FinancesDatatable;
      });

      setFinances(Finances);
    };

    validateFinances();

  }, [dataFinances, handleStatus]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Financeiro</title>
      </Helmet>

      <Grid item marginTop={4} marginBottom={4} lg={12} md={12} sm={12} xs={12} className="titles">
        <Typography variant="h4">{title}</Typography>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Card className="card-form">
          <CardContent>
            <Grid container>
              <Typography variant="h6" className="title-accordion">Selecione os campos, se quiser filtrar os dados</Typography>
            </Grid>

            <Grid container justifyContent="center" alignContent="center" alignItems="center" spacing={1}>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <TextField
                  type="date"
                  id="data_inicial"
                  value={dataIni}
                  label="Data Inicial"
                  error={!dataIni}
                  onChange={(event) => handleInitialDate(event)}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid item lg={4} md={4} sm={4} xs={12}>
                <TextField
                  type="date"
                  id="data_final"
                  value={dataEnd}
                  label="Data Final"
                  error={!dataEnd}
                  onChange={(event) => handleEndDate(event)}
                  InputLabelProps={{ shrink: true, required: true }}
                />
              </Grid>

              <Grid item lg={2} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="ativo"
                  label="Ativo ?"
                  isMultiple={false}
                  required={false}
                  disabled={false}
                  data={dataParentCategory}
                  value={dataActive}
                  onChange={(event) => setDataActive(event ? event.id : 0)}
                />
              </Grid>

              <Grid className="grid-filter-button" item lg={2} md={2} sm={6} xs={12}>
                <Tooltip title="Filtrar">
                  <Button className="btnfilter successBtn" fullWidth variant="contained" onClick={filterClick}><FontAwesomeIcon icon={faFilter} /> Filtrar</Button>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" >
              <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

              <ConfirmDialog
                title={title}
                text={"Deletar"}
                open={openDialog}
                content={contentDialog}
                onClose={handleDeleteCanceled}
                onConfirm={handleDeleteConfirmed}
              />
            </Grid>

          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} id="dataTable">
        <Card className="card-form">
          <CardContent>
            <Tables headers={headers} rows={finances} actions={actions} />
          </CardContent>
        </Card>
      </Grid>

      <ResponsiveDialog
        title={title}
        content={contentModal}
        onClose={modalClose}
        openModal={openModal}
        isFullscreen={fullscreen}
      />

    </Grid>
  )
}

export default ListFinances;