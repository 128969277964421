// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Sidebar Active Button */

.sidebarBtn,
.sidebarIcon,
.iconButtonMenu {
  color: #ffffff !important;
  font-weight: bold;
}

.listText span {
  color: #ffffff !important;
  font-weight: bold;
}

.activeBtn,
.activeIcon {
  background-color: #4BA164 !important;
  color: #ffffff !important;
  opacity: 1 !important;
}

.activeBtn .MuiListItemText-root span,
.activeBtn .MuiListItemIcon-root .icons {
  text-decoration: none;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Sidebar/css/index.css"],"names":[],"mappings":"AAAA,0BAA0B;;AAE1B;;;EAGE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;;EAEE,oCAAoC;EACpC,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;;EAEE,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":["/* Sidebar Active Button */\n\n.sidebarBtn,\n.sidebarIcon,\n.iconButtonMenu {\n  color: #ffffff !important;\n  font-weight: bold;\n}\n\n.listText span {\n  color: #ffffff !important;\n  font-weight: bold;\n}\n\n.activeBtn,\n.activeIcon {\n  background-color: #4BA164 !important;\n  color: #ffffff !important;\n  opacity: 1 !important;\n}\n\n.activeBtn .MuiListItemText-root span,\n.activeBtn .MuiListItemIcon-root .icons {\n  text-decoration: none;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
