import React from 'react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

export default function SelectDynamic({ id, label, value, data, disabled, variant, showLabel, required, onChange }) {
  const isDataEmpty = Object.keys(data).length === 0;
  return (
    <FormControl variant={variant} required={required}>
      {showLabel ? <InputLabel id={`label_${id}`}>{label}</InputLabel> : ''}
      <Select
        id={id}
        label={label}
        value={value || ''}
        disabled={disabled}
        onChange={onChange}
        labelId={`label_${id}`}
      >
        {isDataEmpty ? (
          <MenuItem value="">Nenhuma opção encontrada</MenuItem>
        ) : (
          data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))
        )}
      </Select>
    </FormControl>
  );
}