import * as symbols from './symbols';

export const REDIRECT_ROUTE_HOME = "/";
export const REDIRECT_ROUTE_LOGIN = "/login";

/* Permissions */
export const INVENTORY_PERMISSION_NAME = "estoque";
export const FINANCES_PERMISSION_NAME = 'financeiro';
export const PROPERTIES_PERMISSION_NAME = "propriedades";
export const AGRONOMIC_PRESCRIPTION_PERMISSION_NAME = "receitas";
export const ACTIVITIES_PERMISSION_NAME = "registro_atividades_pulverizacao";

export const getOriginDestiny = (origem, destino, data) => {

  const tipoOrigemMap = {
    'FAZENDA': data.fazenda_origem_nome,
    'FILIAL': data.filial_origem_nome,
    'FORNECEDOR': data.fornecedor_nome,
    'USO': 'Campo',
  };

  const tipoDestinoMap = {
    'FAZENDA': data.fazenda_destino_nome,
    'FILIAL': data.filial_destino_nome,
    'FORNECEDOR': data.fornecedor_nome,
    'USO': 'Campo',
  };

  const origin = tipoOrigemMap[origem] || '';
  const destination = tipoDestinoMap[destino] || '';

  return { origin, destination };
};

export const getDevice = () => {
  let screenWidth = window.innerWidth;

  if (screenWidth >= 1000) {
    return 'desktop';
  } else if (screenWidth >= 600) {
    return 'tablet';
  } else {
    return 'mobile';
  }
};

export const getCurrentDate = (offsetMonths = 0, offsetDays = 0, tipo) => {
  const today = new Date();

  if (tipo === 'soma') {
    today.setDate(today.getDate() + offsetDays);
    today.setMonth(today.getMonth() + offsetMonths);
  } else {
    today.setDate(today.getDate() - offsetDays);
    today.setMonth(today.getMonth() - offsetMonths);
  }

  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => { resolve(position); },
        (error) => { reject(error); }
      );
    } else {
      reject("A geolocalização não é suportada neste navegador.");
    }
  });
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const WeatherIcon = ({ icon, width }) => {
  try {
    const iconPath = symbols[icon];
    if (iconPath) {
      return <img src={iconPath.default} alt="Weather Icon" width={width} />;
    }
  } catch (error) {
    console.error('Erro ao obter ícone', error);
    return null;
  }
};