import React from 'react';
import Carousel from 'react-material-ui-carousel'
import "./css/index.css";

export default function CarouselSlider({ listImages }) {
  const baseURL = 'https://api.peao.digital';
  const { height, autoPlay, indicators } = {
    height: 313,
    autoPlay: false,
    indicators: false
  };
  return (
      <Carousel {...{ height, autoPlay, indicators }} className='Carousel-Slider'>
      {listImages.map((imagem) => (
        <img key={imagem.id} src={`${baseURL}${imagem.arquivo}`} style={{ height, width: '100%' }} alt={imagem.nome} />
      ))}
    </Carousel>
  );
}
