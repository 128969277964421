import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ConfirmDialog = ({open, onClose, onConfirm, title, content, text}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{content}</Typography>
      </DialogContent>
      <DialogActions>
				<Button className="AlertButton" variant="contained" onClick={onConfirm}>{text}</Button>
				<Button className="BackButton" variant="contained" onClick={onClose}>Voltar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;