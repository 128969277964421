import { useEffect, useState, useCallback } from "react";

import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";

import Alertavel from "../../../Components/Alertavel";
import ResponsiveDialog from "../../../Components/Dialog/Dialog";
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

import { User } from "../../../Models/User";
import { Finances } from "../../../Models/Finances/Finances";
import { CategoryFinances } from "../../../Models/Finances/Category";

import { dataParentCategory } from "../constants";

import "../../../App.css";

const AddFinances = ({ module }) => {
  const [title, setTitle] = useState("REGISTRO DE FINANÇAS");

  const [dataFarm, setDataFarm] = useState([]);
  const [dataCategory, setDataCategory] = useState([]);
  const [dataSubsidiaries, setDataSubsidiaries] = useState([]);

  const [haveSubsidiaries, setHaveSubsidiaries] = useState(false);
  const [haveFarm, setHaveFarm] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [contentAlert, setContentAlert] = useState("");
  const [contentModal, setContentModal] = useState("");

  const [btnReset, setBtnReset] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const user = User.searchUser();
  const token = user.token;

  const { state } = location;

  const [formData, setFormData] = useState({
    id: "",
    data: "",
    ativo: "S",
    valor: "",
    quitado: "",
    descricao: "",
    categoria: "",
    recorrente: "",
    filial: null,
    fazenda: null,
  });

  const Load_Categories = useCallback(async () => {
    const category = new CategoryFinances(token);
    const searchCategory = await category.SearchCategories().then(response => {
      if (!response.erro) {
        const allCategories = categories => categories.flatMap(({ subcategorias, ...categoryData }) => [categoryData, ...(subcategorias ? allCategories(subcategorias) : [])]);
        return allCategories(response);
      } else {
        setContentAlert("Você não possui permissão para consultar as categorias. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataCategory(searchCategory);
  }, [token]);

  const Load_Category = useCallback(async (category_id) => {

    const category = new CategoryFinances(token);
    const searchCategory = await category.SearchCategory(category_id).then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para consultar a categoria. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    return searchCategory;
  }, [token]);

  const handleCategoryChange = async (event) => {
    if (event) {

      setFormData({ ...formData, categoria: event.id, filial: null, fazenda: null });

      const { tipo_limitacao, filiais_limitadas, fazendas_limitadas } = await Load_Category(event.id)

      if (tipo_limitacao === "FILIAL") {
        setHaveFarm(false);
        setHaveSubsidiaries(true);
      } else if (tipo_limitacao === "FAZENDA") {
        setHaveFarm(true);
        setHaveSubsidiaries(false);
      } else {
        setHaveFarm(false);
        setHaveSubsidiaries(false);
      }

      setDataSubsidiaries(filiais_limitadas);
      setDataFarm(fazendas_limitadas);
    }

  }

  const validateForm = () => {
    const requiredFields = [
      'data',
      'valor',
      'quitado',
      'categoria',
      'descricao',
      'recorrente',
    ];

    const isAnyFieldInvalid = requiredFields.some((field) => !formData[field]);

    if (isAnyFieldInvalid) {
      return "Existem campos obrigatórios que não foram preenchidos.";
    }

    return null;
  };

  const saveOnClick = (event) => {
    setOpenAlert(false);

    const dataId = event.currentTarget.getAttribute("data-id");
    const errorMessage = validateForm();

    const showSuccessAlert = (message) => {
      setOpenModal(true);
      setContentModal(
        <Grid container alignContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alertavel title="" type="success" open={true} content={message} />
          </Grid>
        </Grid>
      );
    };

    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setContentAlert(errorMessage);
      setOpenAlert(true);
      return;
    }

    if (dataId) {
      const finances = new Finances(token);
      finances.UpdateFinances(dataId, formData);

      showSuccessAlert("Dados Atualizados com sucesso");
    } else {
      delete formData.id;
      const finances = new Finances(token);
      finances.SaveFinances(formData);

      showSuccessAlert("Dados Gravados com sucesso");
    }
  }

  const modalClose = () => {
    setOpenModal(false);
    handleResetPage();
  };

  const handleResetPage = useCallback(() => {
    const routes = module.rotas.find(element => element.tipo_rota === 'CADASTRO' && element.referencia === 'financas');
    navigate(`/financeiro${routes.rota}`);

    setTitle("REGISTRO DE FINANÇAS");

    setFormData({
      id: "",
      data: "",
      ativo: "S",
      valor: "",
      quitado: "",
      descricao: "",
      categoria: "",
      recorrente: "",
      filial: null,
      fazenda: null,
    });

    setBtnReset('');
  }, [module.rotas, navigate]);

  useEffect(() => {
    Load_Categories();
  }, [Load_Categories]);

  useEffect(() => {
    const { tipo, data: dataEdit } = state || {};

    const loadData = async () => {
      const { filial, fazenda, recorrente, quitado, valor, data, descricao, categoria, ativo } = dataEdit;

      setTitle("EDITANDO FINANÇA - #" + dataEdit.id);

      const resetButton = (
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Grid container marginBottom={4}>
            <Button className="successBtn" variant="contained" onClick={handleResetPage}>
              Registro de Finanças
            </Button>
          </Grid>
        </Grid>
      );

      setBtnReset(resetButton);

      const { tipo_limitacao, filiais_limitadas, fazendas_limitadas } = await Load_Category(categoria)

      if (tipo_limitacao === "FILIAL" && filial !== null) {
        setHaveFarm(false);
        setHaveSubsidiaries(true);
        setDataSubsidiaries(filiais_limitadas);
      } else if (tipo_limitacao === "FAZENDA" && fazenda !== null) {
        setHaveFarm(true);
        setHaveSubsidiaries(false);
        setDataFarm(fazendas_limitadas);
      } else {
        setHaveFarm(false);
        setHaveSubsidiaries(false);
      }

      const formData = {
        id: dataEdit.id,
        data: data,
        valor: valor,
        quitado: quitado,
        descricao: descricao,
        categoria: categoria,
        recorrente: recorrente,
        filial: filial,
        fazenda: fazenda,
        ativo: ativo,
      };

      setFormData(formData);
    };

    if (tipo === "edicao" && dataEdit) {
      loadData();
    } else {
      handleResetPage();
    }

  }, [state, handleResetPage, Load_Category]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>Financeiro</title>
      </Helmet>

      <Grid item marginTop={4} marginBottom={4} lg={12} md={12} sm={12} xs={12} className="titles">
        <Typography variant="h4">{title}</Typography>
      </Grid>

      {btnReset}

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="title-accordion">Data</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="data"
                  label="Data"
                  type="date"
                  value={formData.data}
                  InputLabelProps={{ shrink: true, required: true }}
                  onChange={(event) => setFormData({ ...formData, data: event.target.value })}
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>


      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="title-accordion">Categoria</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  multiline
                  rows={4}
                  id="descricao"
                  label="Descrição da categoria"
                  value={formData.descricao}
                  onChange={(event) => setFormData({ ...formData, descricao: event.target.value })}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="categoria"
                  label="Categoria"
                  isMultiple={false}
                  required={true}
                  disabled={false}
                  data={dataCategory}
                  value={formData.categoria}
                  onChange={handleCategoryChange}
                />
              </Grid>

              {haveFarm && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <AutocompleteSelect
                    id="fazendas"
                    label="Fazenda"
                    isMultiple={false}
                    required={true}
                    disabled={false}
                    data={dataFarm}
                    value={formData.fazenda}
                    onChange={(event) => setFormData({ ...formData, fazenda: event ? event.id : null })}
                  />
                </Grid>
              )}

              {haveSubsidiaries && (
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <AutocompleteSelect
                    id="filiais"
                    label="Filial"
                    isMultiple={false}
                    required={true}
                    disabled={false}
                    data={dataSubsidiaries}
                    value={formData.filial}
                    onChange={(event) => setFormData({ ...formData, filial: event ? event.id : null })}
                  />
                </Grid>
              )}
            </Grid>

          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="title-accordion">Recorrência e Quitação</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="recorrencia"
                  label="Recorrente ?"
                  isMultiple={false}
                  required={true}
                  disabled={false}
                  data={dataParentCategory}
                  value={formData.recorrente}
                  onChange={(event) => setFormData({ ...formData, recorrente: event ? event.id : null })}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <AutocompleteSelect
                  id="quitado"
                  label="Quitado ?"
                  isMultiple={false}
                  required={true}
                  disabled={false}
                  data={dataParentCategory}
                  value={formData.quitado}
                  onChange={(event) => setFormData({ ...formData, quitado: event ? event.id : null })}
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} marginBottom={8}>
        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="title-accordion">Valor</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="valor"
                  type="number"
                  label="Valor"
                  value={formData.valor}
                  InputLabelProps={{ required: true }}
                  onChange={(event) => setFormData({ ...formData, valor: event.target.value })}
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>

      <footer className="footer">
        <Grid container justifyContent="right">
          <Grid item>
            <Button className="successBtn" variant="contained" onClick={(event) => saveOnClick(event)} data-id={formData.id || ""}>Gravar</Button>
          </Grid>
        </Grid>
      </footer>

      <ResponsiveDialog
        content={contentModal}
        isFullscreen={false}
        openModal={openModal}
        onClose={modalClose}
        title="Financeiro"
      />

    </Grid>
  )
}

export default AddFinances;