// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards {
  height: 100%;
  display: flex;
  font-weight: bold;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #ffffff !important;
  color: #009951 !important;
  box-shadow: 2px 2px 5px 0px #ababab7a !important;
  border-radius: 10px !important;
}

.btnCard{
  height: 100%;
}

.card-products{
  height: 49%;
}

.btnCard:hover {
  scale: 1.01;
  border-radius: 10px;
}

/* Ícones */

.iconProduct {
  width: 50px !important;
  height: 50px !important;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Cards/css/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;EACtB,6BAA6B;EAC7B,oCAAoC;EACpC,yBAAyB;EACzB,gDAAgD;EAChD,8BAA8B;AAChC;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA,WAAW;;AAEX;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".cards {\n  height: 100%;\n  display: flex;\n  font-weight: bold;\n  text-align: center;\n  align-items: center;\n  flex-direction: column;\n  justify-content: space-evenly;\n  background-color: #ffffff !important;\n  color: #009951 !important;\n  box-shadow: 2px 2px 5px 0px #ababab7a !important;\n  border-radius: 10px !important;\n}\n\n.btnCard{\n  height: 100%;\n}\n\n.card-products{\n  height: 49%;\n}\n\n.btnCard:hover {\n  scale: 1.01;\n  border-radius: 10px;\n}\n\n/* Ícones */\n\n.iconProduct {\n  width: 50px !important;\n  height: 50px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
