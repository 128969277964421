import { addFinances, deleteFinances, searchFinance, searchFinances, updateFinances } from "../../Services/API/api";
import { DefaultModel } from "../DefaultModel";

export class Finances extends DefaultModel {
    
  async SearchFinance(id) {
    const response = await searchFinance(this.token, id);
    return (response) || [];
  }

  async SaveFinances(parameters){
    const response = await addFinances(this.token, JSON.stringify(parameters));
    return (response) || [];
  }
  
  async SearchFinances(parameters) {
    const response = await searchFinances(this.token, this.converter_obj_query_string(parameters));
    return (response) || [];
  }

  async UpdateFinances(id, parameters){
    const response = await updateFinances(this.token, id, JSON.stringify(parameters));
    return (response) || [];
  }

  async DeleteFinances(finance_id){
    const response = await deleteFinances(this.token, finance_id);
    return (response) || [];
  }
}