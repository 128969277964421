import { useEffect, useState } from "react";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";


import { User } from "../../Models/User";

import Sidebar from "../../Components/Sidebar";

import { movements } from "./Movements";
import { routesInventory } from "./routes";

import ListItens from "./Pages/ListItens";
import AddMovements from "./Pages/AddMovements";
import ListMovements from "./Pages/ListMovements";

import "../../App.css";

export default function Inventory() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const initialActiveButton = () => {
    const pathname = location.pathname;

    if (pathname.includes("/listagem-itens")) return 2;
    if (pathname.includes("/listagem-movimentacoes")) return 3;
    if (pathname.includes("/cadastro-movimentacoes")) return 4;

    return 1;
  };

  const [activeButton, setActiveButton] = useState(initialActiveButton);

  const navigateToPage = (page, state) => {
    navigate(`/estoque/${page}`, state);
  };

  const validateUser = () => {
    const user = User.searchUser();
    if (user?.isLoggedIn()) {
      const modules = user.modulos;
      const isModuleAvailable = modules.some(element => element.nome_permissao === 'estoque');

      if (isModuleAvailable) {
        setIsLoggedIn(true);
      } else {
        // Redireciona para a página principal se não tiver permissão
        navigate('/');
      }
    } else {
      // Redireciona para a página de login se o usuário não estiver logado
      navigate('/login');
    }
  };

  const buttonClick = (id) => {
    setActiveButton(id);
    switch (id) {
      case 1:
        navigateToPage('');
        break;
      case 2:
        navigateToPage('listagem-itens');
        break;
      case 3:
        navigateToPage('listagem-movimentacoes');
        break;
      case 4:
        navigateToPage('cadastro-movimentacoes', { state: { tipo: 'cadastro' } });
        break;
      default:
        break;
    }

  };

  useEffect(() => {
    validateUser();
  });

  return (
    <Sidebar isLoggedIn={isLoggedIn} links={routesInventory} activeButton={activeButton} onButtonClick={buttonClick}>
      <Routes>
        <Route path="/listagem-itens" element={<ListItens buttonClick={buttonClick} />} />
        <Route path="/listagem-movimentacoes" element={<ListMovements buttonClick={buttonClick} />} />
        <Route path="/cadastro-movimentacoes" element={<AddMovements dataMovements={movements} buttonClick={buttonClick} navigateToPage={navigateToPage} />} />
      </Routes>
    </Sidebar>
  );
}
