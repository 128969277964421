import { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Grid, Card, CardContent, Typography } from '@mui/material';

import AppBar from "../../Components/AppBar";
import Product from "../../Components/Cards";
import CarouselCards from '../../Components/Carousel/Cards';
import CarouselImagem from '../../Components/Carousel/Imagem';
import AutocompleteSelect from '../../Components/Selects/AutocompleteSelect';

import { User } from '../../Models/User';
import { City } from '../../Models/Essential/CEP';
import { Banner } from '../../Models/Essential/Banner';
import { WeatherForecast } from '../../Models/Forecast/Forecast';

import { WeatherIcon, getDevice } from '../../Services/globalFunction';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import { list_products } from './routes';

import "../../App.css";
import "./css/index.css";

export default function Home() {
  const user = User.searchUser();
  const token = user.token;

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [bannerImg, setBannerImg] = useState([]);
  const [modules, setModules] = useState([]);

  const [dataCities, setDataCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(3925);

  const [weeklyForecast, setWeeklyForecast] = useState([]);
  const [currentForecast, setCurrentForecast] = useState([]);

  const validateUser = () => {
    const user = User.searchUser();
    setModules(user.modulos);
    if (user.isLoggedIn()) {
      let cidade = user.cidade_previsao === null ? parseInt(3925) : parseInt(user.cidade_previsao);
      setSelectedCity(cidade)
      setIsLoggedIn(true);
    }
  };

  const LoadCities = async () => {
    const city = new City();
    const cities = await city.SearchCities('RS');
    setDataCities(cities);
  };

  const getCurrentForecast = (forecast, today) => {
    let dataToday = forecast.datas.find((data) => data.data === today);

    if (dataToday === undefined) {
      //Pegar proximo dia valido (nao tem dados do dia atual)
      dataToday = forecast.datas[0]
    }

    const currentData = dataToday.horarios[0].detalhes;
    let description = '';
    let icon = '';

    if (currentData["1 Hora"] && currentData["1 Hora"].descricao) {
      description = currentData["1 Hora"].descricao;
      icon = currentData["1 Hora"].descricao_original;
    } else {
      description = currentData["6 horas"].descricao;
      icon = currentData["6 horas"].descricao_original;
    }

    const dataForecast = {
      descricao: description,
      descricao_original: icon,
      temperatura_atual: Math.round(dataToday.horarios[0].temperatura),
      temperatura_min: Math.round(dataToday.temperatura_min),
      temperatura_max: Math.round(dataToday.temperatura_max),
    };

    return dataForecast;
  }

  const getWeeklyForecast = (forecast, today) => {
    let dataWeeklyForecast = [];

    //const proximosdias = forecast.datas.filter((data) => data.data !== today);

    forecast.datas.forEach(data => {

      let description = '';
      let icon = '';
      let precipitation = '';

      let periods = [];
      for (let hora in data.horarios) {
        let horario = data.horarios[hora];
        if (!(periods.includes(horario.periodo))) {
          //PEgar apenas um horariode cada periodo (Madrugada, Manha, Tarde, Noite)
          periods.push(horario.periodo)

          if (horario.detalhes["1 Hora"] && horario.detalhes["1 Hora"].descricao) {
            description = horario.detalhes["1 Hora"].descricao;
            icon = horario.detalhes["1 Hora"].descricao_original;
            precipitation = horario.detalhes["1 Hora"].precipitacao;
          } else if (horario.detalhes["6 horas"] && horario.detalhes["6 horas"].descricao) {
            description = horario.detalhes["6 horas"].descricao;
            icon = horario.detalhes["6 horas"].descricao_original;
            precipitation = horario.detalhes["6 horas"].precipitacao;
          } else {
            description = '';
          }

          const dataForecast = {
            data: data.data,
            descricao: description,
            descricao_original: icon,
            precipitacao: precipitation,
            horario: horario.horario.substring(0, 5),
            temperatura_max: Math.round(data.temperatura_max),
            temperatura_min: Math.round(data.temperatura_min),
          }

          dataWeeklyForecast.push(dataForecast);
        }
      }


    });

    return dataWeeklyForecast;
  }

  const LoadWeatherForecast = useCallback(async () => {
    if (selectedCity) {

      const weatherForecast = new WeatherForecast();
      const forecast = await weatherForecast.SearchWeatherForecast(selectedCity);
      if (forecast.datas && forecast.datas.length > 0) {
        const today = new Date().toLocaleDateString('pt-BR');

        const TodayForecast = getCurrentForecast(forecast, today);
        const WeeklyForecast = getWeeklyForecast(forecast, today);

        setCurrentForecast(TodayForecast);
        setWeeklyForecast(WeeklyForecast);
      }
    }
  }, [selectedCity]);

  const LoadBanners = async () => {
    const device = getDevice();
    const Banners = new Banner();
    const banners = await Banners.SearchBanner();
    const filteredImages = banners
      .filter((banner) => banner.ativo === 'S')
      .flatMap((banner) => {
        const images = banner.imagens
          .filter((imagem) => imagem.tipo === (device === 'desktop' ? 'DESKTOP' : 'MOBILE'))
          .map((image) => ({ ...image, bannerNome: banner.nome }));

        return images;
      });

    setBannerImg(filteredImages);
  };

  const handleCityChange = async (event) => {
    if (event) {
      const user = User.searchUser();
      user.saveCity(event.id)
      setSelectedCity(event.id);
    }
  }

  useEffect(() => {
    validateUser();
  }, [token]);

  useEffect(() => {
    LoadCities();
    LoadBanners();
  }, [])

  useEffect(() => {
    LoadWeatherForecast();
  }, [LoadWeatherForecast])

  return (
    <Box id="homePage">
      <Helmet>
        <title>Peão Digital - Início</title>
      </Helmet>

      <AppBar isLoggedIn={isLoggedIn} />

      <Grid container className="section-products">
        <Grid item className="divCardsHome" lg={10} md={10} sm={12} xs={12}>

          <Grid item className="divCardPrevisao" lg={6} md={7} sm={12} xs={12}>
            <Card className="cardPrevisaoTempo">
              <CardContent>

                <Grid container justifyContent='space-between' alignContent="center" alignItems="center">

                  <Grid item lg={3} md={6} sm={6} xs={6}>
                    <Typography variant="h6" component="div">Previsão do Tempo</Typography>
                  </Grid>

                  <Grid item lg={3} md={6} sm={6} xs={6}>
                    <AutocompleteSelect
                      id="cidades"
                      label="Cidades"
                      isMultiple={false}
                      required={false}
                      disabled={false}
                      data={dataCities}
                      value={selectedCity}
                      onChange={handleCityChange}
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent='space-around' alignContent="center" alignItems="center">
                  <Grid item lg={4} md={2} sm={2}>
                    <WeatherIcon icon={currentForecast.descricao_original} width="100" />
                  </Grid>

                  <Grid item lg={4} md={2} sm={2}>
                    <Typography variant="h3">{currentForecast.temperatura_atual}ºC</Typography>
                    <Typography>{currentForecast.descricao}</Typography>
                  </Grid>

                  <Grid container item lg={4} md={2} sm={2} justifyContent="center" alignContent="center" alignItems="center">

                    <Grid item display="flex" alignItems="center" alignContent="center">
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: "#ff0000" }} />
                      <Typography variant="h5" marginLeft={1} marginRight={5}>{currentForecast.temperatura_max}ºC</Typography>
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: "#005bd6" }} />
                      <Typography variant="h5" marginLeft={1}>{currentForecast.temperatura_min}ºC</Typography>
                    </Grid>

                  </Grid>
                </Grid>

                <CarouselCards dados={weeklyForecast} />

              </CardContent>
            </Card>
          </Grid>

          <Grid item className="divCardProdutos" lg={6} md={5} sm={12} xs={12}>
            <Product product={list_products} isLoggedIn={isLoggedIn} modules={modules} />
          </Grid>

        </Grid>
      </Grid>

      <Grid container className="section-banner" mt={2}>
        <Grid item lg={10} md={10} sm={11.5} xs={11.5}>
          <CarouselImagem listImages={bannerImg} />
        </Grid>
      </Grid>
    </Box>
  );
}
