import { Helmet } from 'react-helmet-async';
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material"

import { getLocation } from "../../../Services/globalFunction";

import { User } from "../../../Models/User";
import { Activity } from '../../../Models/Activities/Activities';

import Alertavel from "../../../Components/Alertavel";
import ResponsiveDialog from "../../../Components/Dialog/Dialog";
import AutocompleteSelect from "../../../Components/Selects/AutocompleteSelect";

const NotWorkedActivities = ({ module }) => {
  const [title, setTitle] = useState("ATIVIDADE SEM PULVERIZAÇÃO");

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [contentAlert, setContentAlert] = useState("");
  const [contentModal, setContentModal] = useState("");

  const [btnReset, setBtnReset] = useState('');

  const [dataJustificativa, setDataJustifications] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const user = User.searchUser();
  const token = user.token;

  const { state } = location;

  const LoadJustifications = useCallback(async () => {
    const Justifications = new Activity(token);
    const justifications = await Justifications.SearchJustifications().then(response => {
      if (!response.erro) {
        return response;
      } else {
        setContentAlert("Você não possui permissão para listar as justificativas. Por favor, entre em contato com um administrador para obter assistência.");
        setOpenAlert(true);
        return [];
      }
    });

    setDataJustifications(justifications);
  }, [token]);

  // Dados Selecionados e ou inseridos nos campos
  const [formData, setFormData] = useState({
    id: "",
    descricao: "",
    latitude: "",
    longitude: "",
    origem: "site",
    justificativas: [],
    data_atividade: "",
  });

  const handleJustificationsOnChange = (event) => {
    if (event) {
      let updatedJustifications = [];

      event.forEach((val, i) => {
        updatedJustifications.push({ id: val.id, nome: val.nome, ativa: val.ativa });
      });

      setFormData((prevData) => ({ ...prevData, justificativas: updatedJustifications }));
    }
  }

  const handleResetPage = useCallback(() => {
    const routes = module.rotas.find(element => element.tipo_rota === 'CADASTRO' && element.referencia === 'atividade-nao-trabalhada');
    navigate(`/gestor-atividades${routes.rota}`);

    setTitle("ATIVIDADE SEM PULVERIZAÇÃO");

    setFormData({
      id: "",
      descricao: "",
      latitude: "",
      longitude: "",
      origem: "site",
      justificativas: [],
      data_atividade: "",
    });

    setBtnReset('');
  }, [module.rotas, navigate]);

  const validateForm = () => {
    const requiredFields = [
      'origem',
      'descricao',
      'data_atividade',
      'justificativas',
    ];

    const isAnyFieldInvalid = requiredFields.some((field) => !formData[field]);

    if (isAnyFieldInvalid) {
      return "Existem campos obrigatórios que não foram preenchidos.";
    }

    return null;
  };

  const saveOnClick = async (event) => {
    setOpenAlert(false);

    const dataId = event.currentTarget.getAttribute("data-id");
    const errorMessage = validateForm();

    try {
      const position = await getLocation();
      formData.latitude = position.coords.latitude.toString();
      formData.longitude = position.coords.longitude.toString();
    } catch (error) {
      console.error("Erro ao obter geolocalização:", error);
    }

    const showSuccessAlert = (message) => {
      setOpenModal(true);
      setContentModal(
        <Grid container alignContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Alertavel title="" type="success" open={true} content={message} />
          </Grid>
        </Grid>
      );
    };

    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setContentAlert(errorMessage);
      setOpenAlert(true);
      return;
    }

    if (dataId) {
      const listJustifications = formData.justificativas.map((val) => val.id);
      const dataToSubmit = { ...formData, justificativas: listJustifications };

      const activity = new Activity(token);
      const result = await activity.UpdateNotWorkedActivities(dataId, dataToSubmit)

      if (result.erro !== undefined) {
        const [campo, mensagem] = result.erro[0];

        if (campo === "__all__") {
          setContentAlert(mensagem);
        } else if(mensagem.trim() === "" || typeof(result.erro[0]) === 'string') {
          setContentAlert(result.erro[0])
        } else {
          setContentAlert(mensagem);
        }

        setOpenAlert(true);
      } else {
        showSuccessAlert("Dados Atualizados com sucesso");
      }

    } else {
      const listJustifications = formData.justificativas.map((val) => val.id);
      delete formData.id;
      const dataToSubmit = { ...formData, justificativas: listJustifications };

      const activity = new Activity(token);
      const result = await activity.AddNotWorkedActivities(dataToSubmit);

      if (result.erro !== undefined) {
        const [campo, mensagem] = result.erro[0];

        if (campo === "__all__") {
          setContentAlert(mensagem);
        } else if(mensagem.trim() === "" || typeof(result.erro[0]) === 'string') {
          setContentAlert(result.erro[0])
        } else {
          setContentAlert(mensagem);
        }

        setOpenAlert(true);
      } else {
        showSuccessAlert("Dados Gravados com sucesso");
      }
    }

  }

  const modalClose = () => {
    setOpenModal(false);
    handleResetPage();
  };

  useEffect(() => {
    LoadJustifications();
  }, [LoadJustifications])

  useEffect(() => {
    const { tipo, data: dataEdit } = state || {};

    const loadData = async () => {
      let justification = [];
      const { id, descricao, justificativas, data_atividade } = dataEdit;
      const [day, month, year] = data_atividade.split("/");
      const convertedData = `${year}-${month}-${day}`;

      setTitle("EDITANDO ATIVIDADE SEM PULVERIZAÇÃO - #" + dataEdit.id);

      setBtnReset(
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Grid container marginBottom={4}>
            <Button className="successBtn" variant="contained" onClick={handleResetPage}>
              Atividade sem Pulverização
            </Button>
          </Grid>
        </Grid>
      );

      justificativas.forEach((val, i) => {
        justification.push({ id: val.id, nome: val.nome });
      })

      setFormData({
        id: id,
        origem: 'Site',
        descricao: descricao,
        justificativas: justification,
        data_atividade: convertedData
      });


    }

    if (tipo === "edicao" && dataEdit) {
      loadData();
    } else {
      handleResetPage();
    }
  }, [state, handleResetPage]);

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Grid item marginTop={4} marginBottom={4} lg={12} md={12} sm={12} xs={12} className="titles">
        <Typography variant="h4">{title}</Typography>
      </Grid>

      {btnReset}

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>
        <Alertavel title="Atenção" type="warning" open={openAlert} content={contentAlert} />

        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="title-accordion">Datas</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <TextField
                  type="date"
                  id="data_atividade"
                  value={formData.data_atividade}
                  label="Data da Atividade"
                  onChange={(event) => setFormData({ ...formData, data_atividade: event.target.value })}
                  InputLabelProps={{ shrink: true, required: true }}
                />

              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4}>

        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="title-accordion">Problema</Typography>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <AutocompleteSelect
                  id="problema"
                  label="Problema ?"
                  isMultiple={true}
                  required={true}
                  disabled={false}
                  data={dataJustificativa}
                  value={formData.justificativas}
                  onChange={handleJustificationsOnChange}
                />
              </Grid>
            </Grid>

          </CardContent>
        </Card>
      </Grid>

      <Grid item lg={8} md={12} sm={12} xs={12} mb={4} marginBottom={8}>
        <Card className="card-form">
          <CardContent>

            <Grid container>
              <Typography variant="h6" className="title-accordion">Justificativa</Typography>
            </Grid>

            <Grid container spacing={2}>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  multiline
                  rows={4}
                  id="descricao"
                  required={true}
                  value={formData.descricao}
                  label="Descreva a Justificativa"
                  onChange={(event) => setFormData({ ...formData, descricao: event.target.value })}
                />
              </Grid>
            </Grid>
          </CardContent>

        </Card>
      </Grid>

      <footer className="footer">
        <Grid container justifyContent="right">
          <Grid item>
            <Button className="successBtn" variant="contained" onClick={(event) => saveOnClick(event)} data-id={formData.id || ""}>Gravar</Button>
          </Grid>
        </Grid>
      </footer>

      <ResponsiveDialog
        content={contentModal}
        isFullscreen={false}
        openModal={openModal}
        onClose={modalClose}
        title="Gestor de Atividades"
      />

    </Grid>
  );
}

export default NotWorkedActivities;