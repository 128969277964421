import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { WeatherIcon } from '../../../Services/globalFunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faCloudRain } from '@fortawesome/free-solid-svg-icons';
import './css/index.css';


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function CarouselCards({ dados }) {

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
          dots: false
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {
        dados.map((itens, index) => (
          <Card key={index} className="card-carousel">
            <CardContent className="card-carousel-content">
              <Grid container display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
                <WeatherIcon icon={itens.descricao_original} width="50" />
                <Typography variant='subtitle2'>{itens.horario}</Typography>
              </Grid>

              <Grid marginBottom={1} marginTop={1} container display="flex" justifyContent="center" alignContent="center" alignItems="center">
                <Typography variant='subtitle2'>{itens.descricao}</Typography>
              </Grid>

              <Divider />

              <Grid container className='temperaturaGrid' marginTop={1} marginBottom={1}>
                <Grid item marginLeft={1}>
                  <Typography variant='subtitle2'>Temperatura</Typography>
                </Grid>

                <Grid marginLeft={3} item display="flex">

                  <Grid marginRight={2} item display="flex" alignContent="center" alignItems="center">
                    <FontAwesomeIcon icon={faArrowUp} style={{ color: "#ff0000" }} />
                    <Typography marginLeft={1} variant='subtitle1'>{itens.temperatura_max}ºC</Typography>
                  </Grid>

                  <Grid item display="flex" alignContent="center" alignItems="center">
                    <FontAwesomeIcon icon={faArrowDown} style={{ color: "#002bd6" }} />
                    <Typography marginLeft={1} variant='subtitle1'>{itens.temperatura_min}ºC</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className='precipitacaoGrid' marginTop={1} marginBottom={1}>
                <Grid item>
                  <Grid item marginLeft={1}>
                    <Typography variant='subtitle2'>Precipitação</Typography>
                  </Grid>

                  <Grid item display="flex" alignContent="center" alignItems="center" marginLeft={3} >
                    <FontAwesomeIcon icon={faCloudRain} style={{ color: "#002bd6" }} />
                    <Typography marginLeft={1} variant='subtitle1'>{itens.precipitacao} mm</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Divider />

              <Grid marginTop={2} container justifyContent="end">
                <Typography variant='subtitle2'>{itens.data}</Typography>
              </Grid>
            </CardContent>
          </Card>
        ))
      }
    </Slider>
  );
}