import axios from 'axios';

const publicAPI = axios.create({
  headers: {
    "Content-type": "application/json",
    "Accept": "application/json",
    "Authorization": ""
  },
  baseURL: process.env.REACT_APP_BASE_URL,
});

const privateAPI = (config, token) => {
  let api = axios.create({
    headers: {
      "Content-type": "application/json",
      "Accept": "application/json",
      "Authorization": "Token " + token
    },
    baseURL: process.env.REACT_APP_BASE_URL,
  })
  return api(config)
};

export const ManageError = (error) => {
  let msg = {};
  if (error.response) {
    //Requisição foi efetuada e resposta foi recebida
    msg = { erro: 'Um erro aconteceu, tente novamente mais tarde ou contate o suporte!' }
    if (error.response.status >= 500) {
      console.log(error.response)
    } else {
      let res = error.response.data

      if (typeof res === 'string' || res instanceof String) {
        msg = res
      } else if ('detail' in res) {
        msg = { erro: res.detail }
      } else if ('erro' in res) {
        msg = { erro: res.erro }
      } else if ('non_field_errors' in res) {
        msg = { erro: res.non_field_errors }
      }

    }

  } else if (error.request) {
    //Servidor recebeu a requisição mas não respondeu
    msg = { erro: "Um erro aconteceu ao processar a requisição!" }
    console.log(error)
  } else {
    //Requisição não foi efetuada, verificar a função que disparou esta requisição
    msg = { erro: "Houve um erro ao executar a requisição!" }
    console.log(error)
  }

  return msg;
};

/* Autenticação */

export const Auth = (username, password) => {
  let data = JSON.stringify({
    "username": username,
    "password": password
  });

  let config = { method: "POST", url: `/token/`, data: data }
  return publicAPI(config)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Essenciais */

export const searchModules = (token) => {
  let config = { method: "GET", url: `/v1/essenciais/modulos` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Banner com Imagens */

export const searchBanner = () => {
  let config = { method: "GET", url: `/v1/essenciais/banners` }

  return publicAPI(config)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Propriedade */

export const searchProperty = (token, parameters) => {
  let config = { method: "GET", url: `/v1/propriedades/fazendas?ativa=S&${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchPropertyInfo = (token, propertyId) => {
  let config = {
    method: "GET", url: `/v1/propriedades/fazendas/${propertyId}?ativa=S`
  }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchPropertyField = (token, propertyId) => {
  let config = {
    method: "GET", url: `/v1/propriedades/talhoes_por_fazenda?fazendas=${propertyId}`
  }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Plantas */

export const searchCulture = (token) => {
  let config = { method: "GET", url: `/v1/plantas/culturas?ativa=S` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchApplicationType = (token) => {
  let config = { method: "GET", url: `/v1/plantas/tipo_aplicacoes?ativa=S` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Maquinário */

export const searchMachinery = (token) => {
  let config = { method: "GET", url: `/v1/maquinas/maquinas?ativa=S` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchPropertyMachinery = (token, propertyId) => {
  let config = { method: "GET", url: `/v1/maquinas/maquinas_por_fazenda?fazendas=${propertyId}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Organizações */

export const searchSubsidiaries = (token, companyId, parameters) => {

  let config = { method: "GET", url: `/v1/organizacoes/empresas/${companyId}/filiais?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchProvider = (token) => {
  let config = { method: "GET", url: `/v1/organizacoes/fornecedores` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchContractor = (token) => {

  let config = { method: "GET", url: `/v1/organizacoes/contratantes` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchPilot = (token, companyId, pilotStatus) => {
  let config = { method: "GET", url: `/v1/organizacoes/empresas/${companyId}/pilotos?situacao=${pilotStatus}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Estoque */

export const searchGroup = (token) => {
  let config = { method: "GET", url: `/v1/estoque/grupo_itens?ativa=S` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchItemGroup = (token, grupo_id) => {

  let config = { method: "GET", url: `/v1/estoque/grupo_itens/${grupo_id}/itens?ativa=S` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const addInventoryMovement = (token, parameters) => {
  let config = { method: "POST", url: `/v1/estoque/registros/adicionar`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const updateInventoryMovement = (token, id, parameters) => {
  let config = { method: "PUT", url: `/v1/estoque/registros/editar/${id}`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const approveMovement = (token, id) => {
  let config = { method: "PUT", url: `/v1/estoque/registros/aprovar/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const delInventoryItem = (token, id) => {
  let config = { method: "DELETE", url: `/v1/estoque/registros/deletar/registro_item/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const delInventoryMovement = (token, id) => {
  let config = { method: "DELETE", url: `/v1/estoque/registros/deletar/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchInventoryMovement = (token, id) => {
  let config = { method: "GET", url: `/v1/estoque/registros/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchInventoryMovements = (token, parameters) => {
  let config = { method: "GET", url: `/v1/estoque/registros?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const checkInventory = (token, parameters) => {
  let config = { method: "GET", url: `/v1/estoque/?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Receita */

export const addAgronomicPrescription = (token, parameters) => {
  let config = { method: "POST", url: `/v1/receitas/adicionar`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const updateAgronomicPrescription = (token, id, parameters) => {
  let config = { method: "PUT", url: `/v1/receitas/editar/${id}`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const deleteAgronomicPrescription = (token, id) => {
  let config = { method: "DELETE", url: `/v1/receitas/deletar/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const deleteAgronomicPrescriptionItem = (token, id) => {
  let config = { method: "DELETE", url: `/v1/receitas/deletar_item/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchAgronomicPrescription = (token, parameters) => {
  let config = { method: "GET", url: `/v1/receitas/buscar?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const generateAgronomicPrescriptionPDF = (token, id) => {
  let config = { method: "GET", url: `/v1/receitas/gerar_pdf/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

// Atividades

export const searchActivities = (token, parameters) => {
  let config = { method: "GET", url: `/v1/registro_atividades/buscar?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchWorkedActivities = (token, id) => {
  let config = { method: "GET", url: `/v1/registro_atividades/atv_pulverizacao/ver/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchNotWorkedActivities = (token, id) => {
  let config = { method: "GET", url: `/v1/registro_atividades/atv_nao_trabalhada/ver/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchJustifications = (token) => {
  let config = { method: "GET", url: `/v1/registro_atividades/justificativas?ativa=S` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const addWorkedActivities = (token, parameters) => {
  let config = { method: "POST", url: `/v1/registro_atividades/atv_pulverizacao/adicionar`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const addNotWorkedActivities = (token, parameters) => {
  let config = { method: "POST", url: `/v1/registro_atividades/atv_nao_trabalhada/adicionar`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const updateWorkedActivities = (token, id, parameters) => {
  let config = { method: "PUT", url: `/v1/registro_atividades/atv_pulverizacao/editar/${id}`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const updateNotWorkedActivities = (token, id, parameters) => {
  let config = { method: "PUT", url: `/v1/registro_atividades/atv_nao_trabalhada/editar/${id}`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const deleteWorkedActivities = (token, id) => {
  let config = { method: "DELETE", url: `/v1/registro_atividades/atv_pulverizacao/deletar/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const deleteNotWorkedActivities = (token, id) => {
  let config = { method: "DELETE", url: `/v1/registro_atividades/atv_nao_trabalhada/deletar/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const generateActivitiesPDF = (token, parameters) => {
  let config = { method: "GET", url: `/v1/registro_atividades/atv_pulverizacao/pdf?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const generateActivitiesDashboard = (token, parameters) => {
  let config = { method: "GET", url: `/v1/registro_atividades/dashboard?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Buscar Estados e Cidades */

export const searchState = (token) => {
  let config = { method: "GET", url: `/v1/essenciais/estados` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchCities = (uf) => {
  let config = { method: "GET", url: `/v1/essenciais/estados/${uf}/cidades` }

  return publicAPI(config)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Previsão do Tempo */

export const searchWeatherForecast = (city) => {
  let config = { method: "GET", url: `/v1/previsao_tempo/buscar/${city}` }

  return publicAPI(config)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Categorias Financeiro */

export const searchCategories = (token, parameters) => {
  let config = { method: "GET", url: `/v1/financeiro/categorias/buscar?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchCategory = (token, category_id) => {
  let config = { method: "GET", url: `/v1/financeiro/categorias/ver/${category_id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const addCategories = (token, parameters) => {
  let config = { method: "POST", url: `/v1/financeiro/categorias/gravar`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const updateCategory = (token, id, parameters) => {
  let config = { method: "PUT", url: `/v1/financeiro/categorias/atualizar/${id}`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const deleteCategories = (token, id) => {
  let config = { method: "DELETE", url: `/v1/financeiro/categorias/deletar/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

/* Financeiro */

export const searchFinances = (token, parameters) => {
  let config = { method: "GET", url: `/v1/financeiro/registros/buscar?${parameters}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const searchFinance = (token, finance_id) => {
  let config = { method: "GET", url: `/v1/financeiro/registros/ver/${finance_id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const addFinances = (token, parameters) => {
  let config = { method: "POST", url: `/v1/financeiro/registros/gravar`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const updateFinances = (token, id, parameters) => {
  let config = { method: "PUT", url: `/v1/financeiro/registros/atualizar/${id}`, data: parameters }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}

export const deleteFinances = (token, id) => {
  let config = { method: "DELETE", url: `/v1/financeiro/registros/deletar/${id}` }

  return privateAPI(config, token)
    .then((response) => response.data)
    .catch((error) => {
      return ManageError(error)
    })
}